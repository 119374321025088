<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :dense="true" title="Resumen del Documento" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.purchases.retrieveLoader, text: `${(activeDocument || {}).name } Nº ${instance.number}`}" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
         <v-menu v-if="$vuetify.breakpoint.width <= 1920" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-box </v-icon>Descargar documento<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="downloadFile('purchases', `${instance.document_type}_N${instance.number}`, 'purchases', 'xml', instance.id)" :ripple="false">
              <v-list-item-title class="body-2">XML</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="downloadFile('purchases', `${instance.document_type}_N${instance.number}`, 'purchases', 'pdf', instance.id)" :ripple="false">
              <v-list-item-title class="body-2">PDF</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <template v-if="['PEN', null].includes(instance?.status) && $helpers.hasPermission(['add_swapresponse']) && instance?.status !== 'INV'">
          <v-btn class="ml-2" @click="dialogSwap=true, dialogSwapType = 'accept'" :disabled="btnDisable >= 7" outlined :ripple="false">
            <v-icon left>mdi-file-document-check</v-icon>
            Aceptar
          </v-btn>
          <v-btn class="ml-2" @click="dialogSwap=true, dialogSwapType = 'reclaim'" :disabled="btnDisable >= 7" color="primary" :ripple="false">
            <v-icon left>mdi-file-document-remove</v-icon>
            Reclamar
          </v-btn>
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 425px;">
          <div style="position:fixed!important;">
            <v-card class="blue-500 pb-4" width="425">
              <span class="px-4 pt-4 subtitle-1 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 98%;">
                {{instance.document_type | nameDocumentsType}}
              </span>
              <span class="px-4 mt-n2 subtitle-2 white--text d-block">{{ `Nº ${instance.number}` }}</span>
               <v-tooltip color="white-0" right max-width="185px" transition="scale-transition" open-delay="1000">
                <template v-slot:activator="{ on }">
                  <span class="px-4 mt-4 d-block white--text body-1 d-inline-block text-truncate" style="max-width: 98%;" v-on="instance?.supplier?.name?.length > 40 ? on : false">
                    {{((instance || {}).supplier || {}).name}}
                  </span>
                </template>
                <span class="d-block px-3 py-2">{{((instance || {}).supplier || {}).name}}</span>
              </v-tooltip>
              <span class="px-4 subtitle-2 font-weight-bold white--text">{{ ((instance || {}).supplier || {}).tax_id}}</span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha del doc.</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold" v-if="instance.date">{{instance.date | date}}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </span>
                </v-col>
                <v-col cols="6" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha de recepción en SII</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold" v-if=" instance.date_recep_sii">{{instance.date_recep_sii | dateTimeSecond}}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </span>
                </v-col>
              </v-row>
              <v-col cols="6" class="px-4 pb-4 d-none">
                <span class="d-block body-1 white--text">Sincronizado el</span>
                <span class="subtitle-2 white--text">
                  <span class="font-weight-bold" v-if=" instance.created">{{instance.created | dateTimeSecond}}</span>
                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </span>
              </v-col>
            </v-card>
            <v-card class="pa-0 mt-4" width="425" transition="slide-y-transition" min-height="calc(100% - 365px)" flat style="position:fixed!important;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-4 subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon size="18" left color="grey-500">mdi-currency-usd</v-icon>Totales</v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <v-card-text class="overflow-y-auto pa-0" :style="$vuetify.breakpoint.height <= 700 ? 'max-height: calc(100vh - 420px)' : 'max-height: calc(100vh - 300px)'">
                <!-- totals -->
              <v-col class="px-4">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-1 subtitle-2 grey-500--text font-weight-bold">Totales</span>
                  </v-col>
                </v-row>
                <v-list class="transparent pa-0 mt-2" width="100%">
                  <v-list-item>
                    <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-currency-usd</v-icon>Subtotal</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-bold">{{instance.subtotal | currency(instance.document_type)}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-sale</v-icon>Descuento</v-list-item-title>
                    <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">
                      <template v-if="(instance || {}).amount_discount">
                         <template v-if="instance.discount">
                          {{instance.discount | number}}
                          <template v-if="instance.discount_scheme === '%'">{{instance.discount_scheme}}</template>
                          <template v-if="instance.discount_scheme === '$'">{{instance.document_type | firstDocumentsTypeCurrency}}</template>
                        </template>
                      </template>
                      <template v-else>-</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <template v-if="(activeDocument || {}).has_taxes_detail">
                    <v-list-item class="mt-2">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-hand-coin</v-icon>Exento</v-list-item-title>
                      <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.exempt | currency(instance.document_type)}}</v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="(activeDocument || {}).has_taxes">
                      <v-list-item>
                        <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-hand-coin</v-icon>Neto</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.amount | currency(instance.document_type)}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="mt-2">
                        <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-cash-plus</v-icon>Impuestos ({{activeDocument.tax}}%)</v-list-item-title>
                        <v-list-item-subtitle class="text-right grey-500--text font-weight-bold">{{instance.tax | currency(instance.document_type)}}</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </template>
                  <v-list-item class="mt-2">
                    <v-list-item-title class="grey-500--text font-weight-bold">Total documento</v-list-item-title>
                    <v-list-item-subtitle class="text-right subtitle-2 grey-500--text font-weight-bold">{{instance.total | currency(((instance || {}).document_type || ''))}}
                      <template v-if="Object.keys(instance.exchange || {}).length">{{instance.exchange.currency_from || instance.exchange.currency_to || instance.exchange.currency}}</template>
                      <template v-else>{{((instance || {}).document_type || '') | firstDocumentsTypeCurrency}}</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- end totals -->
              </v-card-text>
            </v-card>
          </div>
        </div>
        <!-- end summary -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">
          <!-- alert -->
          <v-col cols="12" class="pa-0 mb-5" v-if="instance.status === 'PEN' && daysLeftAlert > 0">
            <v-alert text outlined :class="daysLeftAlert >= 5 ? 'inf-alert-blue' : 'inf-alert-yellow'" rounded dense>
              <div class="d-flex pa-0 align-center alert-conten">
                <v-icon :color="daysLeftAlert >= 5 ? 'blue-500' : 'yellow-500'">mdi-calendar-alert</v-icon>
                <div :class="`ml-3 ${daysLeftAlert >= 5 ?'blue-500' : 'yellow-500'}--text`">
                  <span class="body-1 font-weight-bold">Días restantes</span>
                  <span class="d-block body-2 pb-1">
                    Te queda{{daysLeftAlert > 1 ? 'n' : ''}} <span class="font-weight-bold">{{daysLeftAlert }} {{ daysLeftAlert > 1 ? 'días' : 'día'}}</span> para la respuesta comercial del documento
                    <div class="d-block" v-if="btnDisable >= 7">
                      Si deseas aceptar o reclamar este documento, deberá hacerlo directamente en el SII.
                    </div>
                  </span>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" class="pa-0 mb-5" v-if="swap?.status === 'INV'">
            <v-alert text outlined class="inf-alert-yellow" rounded dense>
              <div class="d-flex pa-0 align-center alert-conten">
                <v-icon color="yellow-500">mdi-bank</v-icon>
                <div class="ml-3 yellow-500--text">
                  <span class="body-1 font-weight-bold">{{ swap?.status_detail ? swap?.status_detail : 'Documento inválido'}}</span>
                  <span class="d-block body-2 pb-1">
                    <template>
                      {{ swap?.status_note ? swap?.status_note : 'El documento no se encuentra en el servicio.'}}
                    </template>
                  </span>
                </div>
              </div>
            </v-alert>
          </v-col>
          <!-- end alert -->
          <!-- compliance -->
          <v-card flat class="mb-5" v-if="instance?.status !== 'INV'">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 grey-500--text font-weight-semibold d-flex align-center"><v-icon class="mr-2" size="14" color="grey-500">mdi-shield-check</v-icon>Respuesta comercial
                    </span>
                  </v-col>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-5 py-4" >
              <PermissionDenied class="mt-n8" v-if="!$helpers.hasPermission(['add_swapresponse'])" :isDetail="true" />
              <v-list class="v-list-form-2 transparent pa-0" width="100%" v-else>
                <v-skeleton-loader v-if="loadingSwap" class="rounded-md" type="list-item-three-line" />
                <template v-else>
                  <template v-if="$helpers.hasPermission(['add_swapresponse'])">
                    <v-list-item>
                      <v-list-item-title class="grey-700--text subtitle-2">Estado</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip v-if="['PEN', null].includes(swap.status)" color="yellow-100" class="font-weight-semibold" small label>Sin respuesta<v-icon right>mdi-clock</v-icon></v-chip>
                        <v-chip v-else-if="['ERM', 'PAG', 'ACD'].includes(swap.status)" class="font-weight-semibold" color="blue-100" small label>Aceptado<v-icon right>mdi-check</v-icon></v-chip>
                        <v-chip v-else-if="['RFT', 'RFP', 'RCD'].includes(swap.status)" class="font-weight-semibold" color="red-100" small label>Reclamado<v-icon right>mdi-close</v-icon></v-chip>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="!['PEN', null].includes(swap.status)">
                    <v-list-item-title class="grey-700--text subtitle-2" v-if="swap.status">Respondido a</v-list-item-title>
                    <v-list-item-title class="grey-700--text subtitle-2" v-else>Responder a</v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="body-2 text--disabled font-italic" v-if="!swap.reply_to">Sin especificar</span>
                      {{swap.reply_to}}
                    </v-list-item-subtitle>
                  </v-list-item>
                    <template v-if="!['PEN', null].includes(swap.status)">
                      <v-list-item>
                        <v-list-item-title class="grey-700--text subtitle-2">Detalle del estado</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="swap.status === 'ERM'">Otorga recibo de mercaderías o servicios</span>
                          <span v-else-if="swap.status === 'ACD'">Acepta contenido del documento</span>
                          <span v-else-if="swap.status === 'PAG'">ADTE pagado al contado</span>
                          <span v-else-if="swap.status === 'RCD'">Reclamo al contenido del documento</span>
                          <span v-else-if="swap.status === 'RFP'">Reclamo por falta parcial de mercaderías</span>
                          <span v-else-if="swap.status === 'RFT'">Reclamo por falta total de mercaderías</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item v-if="swap.reply_by">
                        <v-list-item-title class="grey-700--text subtitle-2">Respondido por</v-list-item-title>
                        <v-list-item-subtitle>{{swap.reply_by}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item v-if="swap?.reply_date">
                        <v-list-item-title class="grey-700--text subtitle-2">Procesado el</v-list-item-title>
                        <v-list-item-subtitle>{{swap.reply_date | dateTime}}</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                      <!-- <v-list-item>
                        <v-list-item-title class="grey-700--text subtitle-2">Archivos de respuesta</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          <v-btn class="mr-1" :href="swap.reception" target="_blank" v-if="swap.reception" outlined color="grey-500" small>Recepción</v-btn>
                          <v-btn class="mr-1" :href="swap.receipt" target="_blank" v-if="swap.receipt" outlined color="grey-500" small>Recepción</v-btn>
                          <v-btn class="mr-1" :href="swap.result" target="_blank" v-if="swap.result" outlined color="grey-500" small>Resultado</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item> -->
                      <!-- <v-list-item>
                        <v-list-item-title class="grey-700--text subtitle-2">Respondido el</v-list-item-title>
                        <v-list-item-subtitle>{{swap.reply_date | dateTime}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title class="grey-700--text subtitle-2">Respondido por</v-list-item-title>
                        <v-list-item-subtitle>{{swap.reply_by}}</v-list-item-subtitle>
                      </v-list-item> -->
                    <!-- </template>
                    <v-list-item v-if="$helpers.hasSomePermission(['add_swapresponse', 'add_swapresponse']) && !['PEN', null].includes(swap.status)">
                    <v-list-item-title class="grey-700--text subtitle-2">Respuesta comercial</v-list-item-title>
                    <v-list-item-subtitle>
                      <chip-status v-if="!['PEN', null].includes(swap.status) && $helpers.hasPermission(['add_swapresponse'])" :status="swap.status" />
                      <template v-else-if="$helpers.hasPermission(['add_swapresponse'])">
                        <v-hover v-slot="{ hover }">
                          <v-btn :class="[{'text-decoration-underline' : hover}, 'body-1']" color="primary" text :ripple="false">Aceptar o rechazar</v-btn>
                        </v-hover>
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item> -->
                  </template>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
          <!-- end compliance -->

          <!-- pdf -->
          <v-expansion-panels v-model="panelPDF">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-500--text font-weight-semibold"><v-icon class="mr-2" small color="grey-500">mdi-text-box</v-icon>Visualizar PDF</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0" style="min-height: 300px;">
                <render-pdf :documentType="instance.document_type | nameDocumentsType" :number="instance.number" :loaderRender="loaderRender" :renderUrl="renderUrl" :showToolbar="false" :flat="true" :formatPDF.sync="formatPDF" :showFormat="true"  />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end pdf -->

          <!-- events -->
          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold"><v-icon size="18" left color="grey-500">mdi-timeline-clock</v-icon>Eventos</v-toolbar-title>
            </v-toolbar>
            <v-skeleton-loader v-if="eventLoading" class="mb-2 px-1 rounded-md" type="article" />
            <template v-else-if="eventsList.length">
              <v-divider />
              <v-card-text class="pa-0">
                <v-list class="transparent px-0 py-0">
                  <div v-for="(d, i) in events" :key="i">
                    <v-list-item :class="['px-5 py-0', {'mb-n4' : i !== events.length - 1}]" :key="i">
                      <v-list-item-icon>
                        <v-avatar color="blue-500" :size="28" v-if="d.name === 'sent_email'">
                          <v-icon color="white" :size="12">mdi-email-arrow-right</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="d.name === 'rcv_th_pos'">
                          <v-icon color="white" :size="12">mdi-printer-pos</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="d.name === 'check_purchase'">
                          <v-icon color="white" :size="12">mdi-alert-circle</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="d.name === 'valid_purchase'">
                          <v-icon color="white" :size="12">{{purchaseStatuses.find(({id}) => id = instance.status)?.icon}}</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="['sent_agent', 'sent_final'].includes(d.name)">
                          <v-icon color="white" :size="12">mdi-source-repository</v-icon>
                        </v-avatar>
                        <v-avatar color="blue-500" :size="28" v-else-if="['rcv_th_js', 'rcv_th_ws'].includes(d.name)">
                          <v-icon color="white" :size="12">mdi-cloud-braces</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-n5">
                        <span class="d-block grey-700--text body-1">
                          <span class="font-weight-bold d-flex" v-if="d.name === 'sent_email'">Documento
                            <span class="text-lowercase blue-200--text ml-1">solicitado</span>
                            <v-tooltip v-if="d.name === 'sent_email'" :open-on-hover="emailEventsList(d.detail).length > 60" color="white-0" bottom :nudge-right="30" transition="scale-transition" max-width="300">
                            <template v-slot:activator="{on}">
                                <div v-on="on" class="d-inline-block ml-1 text-truncate" style="max-width: 90%;">
                                  a {{ emailEventsList(d.detail)?.replace(/,/g, ', ') }}
                                </div>
                            </template>
                            <span v-if="emailEventsList(d.detail).length > 60" class="d-block px-3 py-2">{{ emailEventsList(d.detail)?.replace(/,/g, ', ') }}</span>
                          </v-tooltip>
                          </span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_email'">Documento <span class="blue-200--text">solicitado a {{$t('generals.SII')}}</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_pos'">Recibido en <span class="blue-200--text">POS</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_agent'">Enviado al <span class="blue-200--text">agente</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'sent_final'">Enviado al <span class="blue-200--text">agente con estado final</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_js'">Recibido por <span class="blue-200--text">API-REST</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'rcv_th_ws'">Recibido por <span class="blue-200--text">SOAP</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'check_purchase'">Documento <span class="blue-200--text">inválido</span></span>
                          <span class="font-weight-bold" v-else-if="d.name === 'valid_purchase'">Documento <span class="blue-200--text">válido</span></span>
                        </span>
                        <span class="d-block body-2 grey-500--text">
                          <span>{{['valid_purchase', 'check_purchase'].includes(d.name) ? 'Consultado el' : 'por' }} <span class="font-weight-semibold">{{ ['valid_purchase', 'check_purchase'].includes(d.name) ? '' : 'Axteroid' }}</span>{{ ['valid_purchase', 'check_purchase'].includes(d.name) ? ''  : 'a las' }} </span>
                          <span v-if="['valid_purchase', 'check_purchase'].includes(d.name)">{{d.created | dateTimeSecond}}</span>
                          <span v-else>{{d.created | dateTime}}</span>
                        </span>
                       </v-list-item-title>
                    </v-list-item>
                  </div>
                  <template v-if="eventsList.length > 3">
                    <v-divider class="mt-4" />
                    <v-btn class="mt-1 mb-n1 mx-5" color="blue-500" @click="showMoreEvents=!showMoreEvents" text>
                      {{ !showMoreEvents ? 'Ver todos los eventos' : 'Ver menos eventos' }} </v-btn>
                  </template>
                </v-list>
              </v-card-text>
            </template>
          </v-card>
          <!-- end events -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>

    <!-- dialog swap update -->
    <v-dialog v-model="dialogSwap" width="410" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-300--text--grey-500 font-weight-semibold">{{ dialogSwapType !== 'reclaim' ? 'Aceptar' : 'Reclamar' }} documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSwap=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-8" align="center" justify="center">
            <img :src="require(`@/assets/backgrounds/modal-aceptar-rechazar.svg`)" :width="370" />
          </v-row>
          <v-col class="pt-8 px-0 pb-0">
            <v-list class="pa-0 transparent" three-line>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title :class="{'font-weight-semibold' : dialogSwapType !== 'reclaim'}">{{ dialogSwapType === 'reclaim' ? 'Selecciona la respuesta al documentos.' : 'Acuse de recibo del documento' }}</v-list-item-title>
                  <v-list-item-subtitle class="pt-2" v-if="dialogSwapType === 'reclaim'">
                    <v-autocomplete v-model="swapResponse.status" :items="swapStatuses" item-text="label" item-value="value" single-line hide-details dense return-object outlined />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogSwap=false" outlined>Cancelar</v-btn>
          <v-btn @click="updateSwap" :loading="$store.state.irs.createLoader" color="primary">{{ dialogSwapType !== 'reclaim' ? 'Aceptar' : 'Reclamar' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog swap update -->

    <!-- dialog confirm swap update -->
    <v-dialog v-model="dialogConfirmSwap" width="838" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-300--text--grey-500 font-weight-semibold">{{ dialogSwapType !== 'reclaim' ? 'Aceptar' : 'Reclamar' }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmSwap=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              Se registrará el evento <span class="font-weight-bold text-lowercase">{{swapResponse.status.label || swapStatuses[0].label }}</span> en el registro de compras del {{$t('generals.SII')}}, y se le enviará, una notificación al proveedor informando la respuesta del documento.

              <span class="d-block body-1 pt-3">Al confirmar esta acción, no habrá vuelta atrás.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />

              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-4" :height="50" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4 text-description">
                    <span class="body-2" style="line-height: 14px;">Esta acción
                      <span class="font-weight-bold">podría tardar varios minutos</span> en completarse.
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirmSwap=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogSwap=false, updateSwap()" :loading="$store.state.irs.createLoader" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm swap update -->
  </v-row>
</template>

<script>
  import moment from 'moment'
  import { mapState } from 'vuex'
  import MiniHeader from '@/components/commons/MiniHeader'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import DocumentsList from '@/modules/documents/components/DocumentsList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import DocumentTypes from '@/collections/documentTypes'
  import purchaseStatuses from '@/collections/purchaseStatuses'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      ChipStatus,
      MiniHeader,
      VModal,
      SkeletonDetail,
      RenderPdf,
      DocumentsList,
      PermissionDenied
    },
  data: () => ({
      eventLoading: false,
      formatPDF: 1,
      scroll: 0,
      confirm: '',
      comments: '',
      dialogConfirmSwap: false,
      loading: false,
      loadingSwap: false,
      dialogSwap: false,
      dialogSwapType: '',
      panelIrsSii: [],
      panelComments: false,
      panelEmail: false,
      panelPDF: 0,
      panelRelatedDocuments: false,
      panelTotals: false,
      swap: {},
      swapResponse: {
        status: 'ERM'
      },
      swapStatuses: [
        // { value: 'ERM', label: 'Otorga recibo de mercaderías o servicios' },
        // { value: 'ACD', label: 'Acepta contenido del documento' },
        { value: 'RCD', label: 'Reclamo al contenido del documento' },
        { value: 'RFP', label: 'Reclamo por falta parcial de mercaderías' },
        { value: 'RFT', label: 'Reclamo por falta total de mercaderías' }
      ],
      breadcrumbs: {
        main: 'Recepción',
        children: [
          {
            text: '',
            route: 'PurchaseList'
          }
        ]
    },
    purchaseStatuses
    }),
    computed: {
      ...mapState({
        instance: state => state.purchases.purchasesDetail,
        eventsList: state => state.purchases.purchase_eventsList
      }),
      activeDocument () {
        return DocumentTypes.find(d => d.id === this.instance.document_type)
      },
      daysLeftAlert () {
        const date = moment(this.instance.date_recep_sii).add(8, 'days')
        const duration = moment.duration(moment().diff(date))
        const days = Math.floor(duration.asDays())
        return days * (-1) > 0 && days * (-1) < 9 ? days * (-1) : null
      },
      btnDisable () {
        const duration = moment.duration(moment().diff(this.instance.date_recep_sii))
        const days = Math.floor(duration.asDays())

        return days * (-1) > 0 ? days * (-1) : days
      },
      events () {
        return !this.showMoreEvents ? this.eventsList.slice(0, 3) : this.eventsList
      },
      emailEventsList () {
        return (emails = null) => {
          const list = emails.match(/\[(.*?)\]/g).map(x => x.slice(1, -1))
          return list.toString().split(", ").map(email => email.replace(/'/g, "")).toString() // emails.match(/\[(.*?)\]/g).map(x => x.slice(1, -1)) // .toString().replace(/\'/g, '')
        }
      }
    },
    watch: {
      panelPDF (val) {
        this.modalRender = val
        if (val === 0) {
          this.$vuetify.goTo(0, { duration: '0', easing: 'linear' })
          if (!this.renderUrl) this.renderDocument('purchases')
        }
        this.formatPDF = 1
      },
      formatPDF: {
        handler (val) {
          if (val === 1) this.renderDocument('documents')
          else {
            this.renderDocument('purchases', 'purchases', 'pdf', this.instance.id, { thermal_format: val})
          }
        },
        inmediate: true,
        deep: true
      }
    },
  created () {
    this.eventLoading = true
      this.breadcrumbs.children[0].text = `${this.$store?.state?.auth?.account?.auto_purchase_sii ? 'Recibidos' : 'Documentos recibidos'}`
      this.$store.dispatch('purchases/RETRIEVE', {
        resource: 'purchases',
        id: this.$route.params.id,
        query: {
          expand: 'supplier'
        }
      })
      .then(() => {
        if (!this.renderUrl) this.renderDocument('purchases')
        this.loadingSwap = true
        if (this.$helpers.hasPermission(['add_swapresponse'])) {
          this.$store.dispatch('irs/LIST', {
            resource: 'irs/sii/swaps',
            query: {
              purchases__in: this.$route.params.id
            }
          })
          .then((response) => {
            if (response.data.results) {
              this.swap = response.data.results[0]
            }
          })
          .finally(() => {
            this.loadingSwap = false
          })
        }
        this.$store.dispatch('purchases/LIST', {
          resource: 'purchase_events',
          query: {
            purchase: this.instance.id
          }
        })
        .finally(() => {
          this.eventLoading = false
        })
      })
    },
    methods: {
      onScroll (e) {
        this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
      },
      async handlePanel () {
        await this.$root.$emit('isClose', true)
        this.$router.replace({ name: 'DocumentsList'})
      },
      updateSwap () {
        this.$store.dispatch('irs/UPDATE', {
          resource: 'irs/sii/swaps',
          id: this.swap.id,
          payload: {
            status: this.dialogSwapType !== 'reclaim' ? 'ERM' : this.swapResponse.status.value,
            purchase: this.instance.id
          }
        })
        .then((response) => {
          this.swap = response.data
        })
        .finally(() => {
          this.dialogSwap = false
          this.confirm = ''
          this.dialogSwapType = ''
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      }
    }
  }
</script>
<style scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle,
.v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 2 !important;
}

.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: auto;
}

.inf-alert-blue.v-alert--text.v-alert--outlined:not(.inf-alert) {
  border-radius: 10px !important;
  border: 1px solid var(--light-blue-500) !important;
}

.inf-alert-blue.v-alert--text:not(.inf-alert):before {
  background-color: var(--light-blue-500) !important;
  opacity: .3 !important;
}

.inf-alert-yellow.v-alert--outlined:not(.inf-alert-blue) {
  border-radius: 10px !important;
  border-color: var(--light-yellow-500) !important;
}
.alert-conten {
  position: relative;
}
.inf-alert-yellow:not(.inf-alert-blue).v-alert--text:before {
  background-color: #F8E4B1 !important;
  opacity: .8
}
</style>